import React from 'react';
import PropTypes from 'prop-types';
import Container from './Container';

function Banner({ title, title2, backgroundImage }) {
  return (
    <section className={`banner ${backgroundImage}`}>
      <Container>
        {title && <h1 className="title1">{title}</h1>}
        {title2 && <h3 className="title2">{title2}</h3>}
      </Container>
    </section>
  );
}

Banner.propTypes = {
  title: PropTypes.string,
  title2: PropTypes.string,
  backgroundImage: PropTypes.string,
};

export default Banner;
