/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Row, Col, Pagination } from 'antd';
import { graphql } from 'gatsby';
import Container from '../components/Container';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import { useSearch } from '../utils/common';

const PAGE_SIZE = 5;

function Article({ title, author, date, cover, description, path }) {
  return (
    <Row className="article" gutter={30}>
      <Col lg={{ span: 10 }} xs={{ span: 24 }} className="blog-image-wrapper">
        <img alt="cover" src={cover} className="img-fluid" />
      </Col>
      <Col className="articleDesc" lg={{ span: 14 }} xs={{ span: 24 }}>
        <div className="content">
          <div className="title">
            <a target="_blank" href={path} rel="noreferrer">
              {title}
            </a>
          </div>
          <div className="description">{description}</div>
        </div>
        <div className="bottomSection">
          <div className="left">
            <div className="author">作者：{author}</div>
            <div className="date">{date}</div>
          </div>
          <div className="right">
            <a target="_blank" href={path} rel="noreferrer">
              查看全文 &gt;
            </a>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ({ data }) => {
  const search = useSearch();
  const [current, setCurrent] = useState(1);

  const { edges, totalCount } = data.allMdx;

  function onPageChange(page) {
    setCurrent(page);
  }

  return (
    <Layout current="activities" subCurrent="activities">
      <Banner title="活动中心" title2="数智赋能每一个岗位" backgroundImage="activities-banner" />
      <section className="resources">
        <Container>
          {edges
            .slice((current - 1) * PAGE_SIZE, (current - 1) * PAGE_SIZE + PAGE_SIZE)
            .map(({ node }) => (
              <Article
                key={node.id}
                {...node.frontmatter}
                path={`/blog/${node.slug}${search}`}
              />
            ))}
          <div className="pagiContainer">
            <Pagination
              total={totalCount}
              pageSize={5}
              hideOnSinglePage
              current={current}
              onChange={onPageChange}
            />
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { type: { eq: "activity" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          slug
          frontmatter {
            title
            author
            date
            cover
            description
          }
        }
      }
      totalCount
    }
  }
`;
